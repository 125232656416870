import { FC, useState } from "react";
import { Modal } from "antd";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import { ModalNativeLink } from "./ModalNativeLink";

export interface Notification {
  body: string;
  createdAt: string;
  data: any;
  messageId: string;
  read: boolean;
  title: string;
  userId: string;
}

export interface ModalNotificationProps {
  showModal: boolean;
  closeModal: () => void;
  notification: Notification;
}

export const ModalNotification: FC<ModalNotificationProps> = ({
  showModal,
  closeModal,
  notification,
}) => {
  const [showModalNativeLink, setShowModalnativeLink] =
    useState<boolean>(false);

  const orderLinksPriority = (links: any) => {
    links.sort((a: any, b: any) =>
      a?.priority > b?.priority ? 1 : b?.priority > a?.priority ? -1 : 0
    );

    return links;
  };

  const links =
    notification?.data?.links !== undefined
      ? typeof notification?.data?.links !== "string"
        ? orderLinksPriority(notification?.data?.links)
        : orderLinksPriority(JSON.parse(notification?.data?.links))
      : false;

  const url =
    notification?.data?.url !== undefined ? notification?.data?.url : false;

  const buttonText =
    notification?.data?.buttonText !== undefined
      ? notification?.data?.buttonText
      : false;

  return (
    <Modal
      className="modal-notification"
      title=" "
      centered
      open={showModal}
      onCancel={() => closeModal()}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      footer={null}
      width={732}
    >
      <p className="notification-title">{notification.title}</p>
      <p className="notification-date">
        {dayjs(notification.createdAt).format("DD/MM/YYYY - HH:mm")}
      </p>
      <p className="notification-description">{notification.body}</p>

      {buttonText && url && (
        <>
          <Button
            variant="contained"
            onClick={() => (window.location.href = url)}
            fullWidth
          >
            {buttonText} Teste
          </Button>
        </>
      )}

      {links &&
        links.map((link: any, i: number) => {
          const isFirstButton = i === 0 && !buttonText && !url ? true : false;

          if (link?.rel === "webview" || link?.rel === "external_link") {
            return (
              <div style={{ marginTop: 10 }}>
                <Button
                  onClick={() => (window.location.href = link.href)}
                  variant={isFirstButton ? "contained" : "text"}
                  key={`${i}-notif`}
                  fullWidth
                >
                  {link.title}
                </Button>
              </div>
            );
          }

          if (link?.rel === "native") {
            return (
              <div style={{ marginTop: 10 }}>
                <Button
                  onClick={() => setShowModalnativeLink(true)}
                  variant={isFirstButton ? "contained" : "text"}
                  key={`${i}-notif`}
                  fullWidth
                >
                  {link.title}
                </Button>
                <ModalNativeLink
                  showModal={showModalNativeLink}
                  closeModal={() => setShowModalnativeLink(false)}
                />
              </div>
            );
          }

          return <div key={`${i}-notif`}></div>;
        })}
    </Modal>
  );
};
